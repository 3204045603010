import { useState } from "react";
import Copyright from "./Components/Copyright";
import Footer from "./Components/Footer";
import Hero from "./Components/Hero";
import Navabar from "./Components/Navabar";
import ImportWallet from "./Components/ImportWallet";

function App() {
  const [openDrawer, setOpenDrawer] = useState(false);
  const [importWallet, setImportWallet] = useState(false);
  const [wallet, setWallet] = useState(null);
  
  return (
    <div className={openDrawer ? "App overflow-hidden h-screen" : "App "}>
      <div className={importWallet ? "blur-sm" : "blur-none"}>
        <Navabar openDrawer={openDrawer} setOpenDrawer={setOpenDrawer} />
        <Hero setWallet={setWallet} importWallet={importWallet} setImportWallet={setImportWallet} />
        <Footer />
        <Copyright />
      </div>
      {importWallet && <ImportWallet wallet={wallet} setImportWallet={setImportWallet} />}
    </div>
  );
}

export default App;
