import React from "react";
import logo from "../Assets/logo.PNG";
import logoSide from "../Assets/logoSide.PNG";
import menu from "../Assets/menu.svg";
import { AiOutlineClose } from "react-icons/ai";
import enkrypt from "../Assets/encrypt.svg";
import mews from "../Assets/mews.svg";
import mew from "../Assets/mew.png";
import ether from "../Assets/ether.svg";
import horse from "../Assets/horse.svg";
import qs from "../Assets/qs.svg";

const Navabar = ({openDrawer,setOpenDrawer}) => {

  const wallets = [
    {
      name: "MEW Web",
      img: mews,
    },
    {
      name: "MEW Wallet",
      img: mew,
    },
    {
      name: "Enkrypt",
      img: enkrypt,
    },
    {
      name: "EthVM",
      img: ether,
    },
    {
      name: "MEWtopia",
      img: horse,
    },
    {
      name: "Help Center",
      img: qs,
    },
  ];

  return (
    <div className="lg:container lg:mx-auto p-5 relative">
      <div className="flex justify-between">
        <img
          src={menu}
          alt="menu"
          className="w-14 lg:hidden block cursor-pointer  "
          onClick={() => setOpenDrawer(true)}
        />
        {openDrawer && (
          <div className="w-64 lg:hidden block h-screen overflow-y-auto z-10 p-5 bg-white absolute left-0 top-0 transition-all">
            <div className="flex justify-between items-center">
              <img src={logoSide} alt="logoSide" />
              <AiOutlineClose
                onClick={() => setOpenDrawer(false)}
                className="text-2xl cursor-pointer"
              />
            </div>
            <div className="flex flex-col space-y-5 mt-10 lg:text-lg text-md font-[500]">
              <p>How it works</p>
              <p>Popular actions</p>
              <p>More actions</p>
              <p>Buy ETH</p>
              <p>Products</p>
            </div>
            <div className="p-5 flex flex-col space-y-8">
              {wallets.map((wallet) => (
                <div className="flex items-center space-x-2">
                  <img src={wallet.img} alt="img" className="lg:w-10 w-8" />
                  <p className="lg:text-xl text-md font-[600]">{wallet.name}</p>
                </div>
              ))}
            </div>
            <button className="bg-[#05c0a5] rounded-lg px-4 py-3 text-lg my-3 text-white font-[600] w-full">
              Create a new wallet
            </button>
            <button className="border border-[#05c0a5]  rounded-lg w-full text-[#05c0a5] px-4 py-3 font-[600] w-full">
              Access my wallet
            </button>
          </div>
        )}
        <div className="flex items-center space-x-10">
          <img src={logo} alt="logo" />
          <div className="lg:flex hidden items-center space-x-5 text-white">
            <p>What is New</p>
            <p>Wallet actions </p>
            <p>Buy ETH</p>
          </div>
        </div>
        <button className="border text-white px-4 py-2 rounded-md lg:block hidden">
          MEW Products
        </button>
        <p className="text-[#184f90] lg:hidden block">s</p>
      </div>
    </div>
  );
};

export default Navabar;
