import React from "react";
import bitcoin from "../Assets/bitcoin.png";
import ethe from "../Assets/ethe.png";
import {
  Facebook,
  Instagram,
  Telegram,
  Twiter,
  UnknowM,
  Youtube,
} from "../Assets/SvgExporter";
import {IoIosArrowDown} from 'react-icons/io'

const Footer = () => {
  return (
    <div className="bg-white">
      <div className="grid lg:grid-cols-4 md:grid-cols-2 grid-cols-1 gap-5  py-5 lg:p-10">
        <div className="flex flex-col space-y-2">
          <div className="flex items-center justify-between lg:border-b-0 border-b lg:py-0 px-2 py-4">
            <p className="font-[700] lg:text-md text-xl">Affiliate Hardware Wallets</p>
            <IoIosArrowDown className="lg:hidden md:hidden block"/>
          </div>
          <div className='lg:block hidden'>
            <p>Ledger</p>
            <p>BitBox02</p>
            <p>Ether Cards</p>
            <p>Trezor</p>
            <p>KeepKey</p>
            <p>Finney</p>
            <p>CoolWallet</p>
            <p>Billfodl</p>
          </div>
        </div>

        <div className="flex flex-col space-y-2">
          <div className="flex items-center justify-between lg:border-b-0 border-b lg:py-0 px-2 py-4">
            <p className="font-[700] lg:text-md text-xl">MEW</p>
            <IoIosArrowDown className="lg:hidden md:hidden block"/>
          </div>
          <div className='lg:block hidden'>
            <p>About us</p>
            <p>Careers</p>
            <p>How it works</p>
            <p>Team</p>
            <p>Help center</p>
            <p>Customer support</p>
            <p>MEWtopia</p>
            <p>Press Kit</p>
            <p>Security Policy</p>
            <p>Submit DApp</p>
          </div>
        </div>
        <div className="flex flex-col space-y-2">
          <div className="flex items-center justify-between lg:border-b-0 border-b lg:py-0 px-2 py-4">
            <p className="font-[700] lg:text-md text-xl">Tools</p>
            <IoIosArrowDown className="lg:hidden md:hidden block"/>
          </div>
          <div className='lg:block hidden'>
            <p>MEW wallet</p>
            <p>Enkrypt</p>
            <p>Verify message</p>
            <p>Convert units</p>
            <p>Generate keystore file</p>
            <p>Send Offline Helper</p>
          </div>
        </div>
        <div className="flex flex-col space-y-2 lg:w-64 px-2 py-4">
          <p className="font-[700] lg:text-md text-xl">Love MEW?</p>
          <p>
            Help us keep MEW free and open-source, your donations go a long way
            towards making that possible.
          </p>
          <div className="flex items-center space-x-2">
            <img src={ethe} alt="ethe" className="w-10" />
            <p>Ethereum Donation</p>
          </div>
          <div className="flex items-center space-x-2">
            <img src={bitcoin} alt="ethe" className="w-10" />
            <p>Bitcoin Donation</p>
          </div>
        </div>
      </div>
      <div className="container mx-auto flex lg:flex-row flex-col justify-around px-10 py-5 space-y-5">
        <div className="flex justify-around">
          <p>Feedback</p>
          <p>Privacy</p>
          <p>Terms</p>
        </div>
        <div className="lg:flex hidden space-x-5 ">
          <p>Data Tracking Off</p>
        </div>
        <div className="flex justify-between text-[#5f7793]">
          <Telegram />
          <Instagram />
          <Facebook />
          <Twiter />
          <UnknowM />
          <Youtube />
        </div>
      </div>
    </div>
  );
};

export default Footer;
