import React from "react";
import enkrypt from "../Assets/encrypt.svg";
import mew from "../Assets/mew.png";
import metamask from "../Assets/metamask.png";
import walletCn from "../Assets/walletCn.svg";
import { BsShieldFillCheck } from "react-icons/bs";

const Hero = ({ setImportWallet, setWallet }) => {
  const wallets = [
    {
      name: "Enkrypt",
      title: "Connect with enkrypt browser extension",
      img: enkrypt,
    },
    {
      name: "Mew",
      title: "Connect MEW Wallet app with MEW web",
      img: mew,
    },
    {
      name: "WalletConnect",
      title: "Connect with WalletConnect browser extension",
      img: walletCn,
    },
    {
      name: "MetaMask",
      title: "Connect with MetaMask browser extension",
      img: metamask,
    },
  ];

  return (
    <div className="flex flex-col items-center justify-center py-20 px-4">
      <div className="text-center ">
        <p className="text-white text-3xl font-[700] ">Access My Wallet</p>
        <p className="text-white text-lg my-4">
          Please select a method to access your wallet.
        </p>
        <p className="text-white text-lg my-4">
          Don't have a wallet? Create Wallet
        </p>
      </div>
      <div className="my-20  flex flex-col space-y-5">
        {wallets.map((wallet) => (
          <div
            onClick={() => {
              setWallet(wallet.img);
              setImportWallet(true);
            }}
            className="bg-white rounded-lg p-10 relative lg:w-[44rem] cursor-pointer "
          >
            <div className="flex items-center space-x-10 ">
              <img src={wallet.img} alt="img" className="lg:w-16 w-10" />
              <div>
                <p className="lg:text-2xl text-lg font-[700]">{wallet.name}</p>
                <p className="lg:text-lg mt-3 lg:block hidden font-[600]">
                  {wallet.title}
                </p>
              </div>
            </div>
            <p className="lg:text-lg mt-3 lg:hidden block font-[600]">
              {wallet.title}
            </p>
            <p className="bg-[#05c0a5] flex space-x-2 items-center lg:rounded-full rounded-md  px-4 py-1 text-white text-lg font-[600] absolute lg:right-5 lg:top-5 right-0 top-0">
              <BsShieldFillCheck />
              <span>Official</span>
            </p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Hero;
