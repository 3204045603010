import React from "react";

const Copyright = () => {
  return (
    <div className="bg-[#1f242f]">
      <div className="container mx-auto flex flex-col space-y-5 p-5 text-[#67deea]">
        <div>
          <p>v6.6.14</p>
        </div>
        <div>
          <p>
            © 2023 MyEtherWallet. All rights reserved. Pricing taken from
            CoinGecko.
          </p>
        </div>
        <div >
          <p>English</p>
        </div>
      </div>
    </div>
  );
};

export default Copyright;
